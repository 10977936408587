















import { Component, Vue } from "vue-property-decorator";
import CardBox from "@/components/card-box/index.vue";
import * as UpgradeApi from "@/api/upgrade";
import * as _ from "lodash";

@Component({
  name: "InstallFileListPage",
  components: {
    CardBox,
  },
})
export default class extends Vue {
  private setupFiles: UpgradeApi.SetupFile[] = [];
  private async __init() {
    this.setupFiles.splice(0, this.setupFiles.length);
    const { data } = await UpgradeApi.getSetupFiles();
    this.setupFiles = _.concat(this.setupFiles, data.items);
    console.log(JSON.stringify(data.items));
  }
  mounted() {
    this.__init();
  }
}
